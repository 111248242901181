@tailwind base;
@tailwind components;
@tailwind utilities;

/* Basic text stylings */
html {
  font-size: 16px;
  @apply font;
  @apply text-1;
}

body {
  line-height: 1.25;
  @apply text-1;
  @apply text-static-primary;
  @apply bg-suplementary-1;
}

/* Helpful for debugging */
.debug,
.debug * * {
  outline: 1px dashed red;
}

/* Removing default input styles */
input {
  outline: none;
  background: none;
  @apply placeholder-static-secondary;
}

/* Adjusting third-party range input module */
.range-slider {
  --primary-color: theme("colors.interactive.primary");
  --track-height: 1px;
  --thumb-size: 1.5rem;
  --thumb-shadow: 0 0 0 0.5rem var(--primary-color) inset,
    0 0 0 99px var(--thumb-color) inset;
  --thumb-shadow-active: 0 0 0 0.75rem var(--primary-color) inset,
    0 0 0 99px var(--thumb-color) inset;
  --ticks-height: 0.25rem;
  --ticks-color: theme("colors.suplementary.3");
  --progress-background: theme("colors.suplementary.3");
  --fill-color: theme("colors.static.primary");
  --thumb-color: theme("colors.white");
}

/* Scrollbar styles: */
::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}
::-webkit-scrollbar-track {
  box-shadow: none;
  border-radius: 0.25rem;
}
::-webkit-scrollbar-thumb {
  border-color: theme("colors.white");
  background-color: theme("colors.interactive.secondary");
  border-radius: 0.5rem;
  cursor: pointer;
}
::-webkit-scrollbar-thumb:hover {
  background-color: theme("colors.interactive.primary");
}

/* Button additional styles */

.button-before::before {
  content: "";
  pointer-events: none;
  top: 0px;
  left: 0px;
  border-radius: inherit;
  border: 1px solid;
  height: calc(100% - 0px);
  width: calc(100% - 0px);
  @apply absolute;
  @apply pointer-events-none;
  @apply opacity-0;
  @apply transition-all;
  @apply border-white;
}
.button-before:hover::before {
  top: 1px;
  left: 1px;
  height: calc(100% - 2px);
  width: calc(100% - 2px);
  @apply opacity-100;
}

/* Ant image fix */

.ant-image-preview-img {
  display: initial;
}

.red-menu-item {
  color: red;
}

/* Ant icon fix */

span.anticon {
  display: inline-flex;
}
/* Ant icon fix*/

.anticon svg {
  display: flex;
}

/* Jodit */

.jodit h1 {
  /*@apply text-2*/
  font-size: 2em;
  font-weight: 500;
}

.jodit h2 {
  font-size: 1.5em;
  font-weight: 500;
}

.jodit h3 {
  font-size: 1.17em;
  font-weight: 500;
}

.jodit h4 {
  font-size: 1em;
  font-weight: 500;
}

.jodit ul {
  padding-inline-start: 40px;
  list-style-type: disc;
}

.jodit ol {
  padding-inline-start: 40px;
  list-style-type: decimal;
}
.underlined-button {
  text-decoration: underline;
  position: absolute;
  bottom: 12%;
  right: 3%;
}
.ant-form-item-required::before {
  content: none;
}
/* orientation viewer */
@media screen and (orientation: portrait) {
  .slide-header {
    padding: 48px 16px 16px 16px;
  }
}

@media screen and (max-height: 550px) and (orientation: landscape) {
  .slide-header {
    margin-top: 0em;
    padding: 0em;
  }
}

@media screen and (min-height: 550px) and (orientation: landscape) {
  .slide-header {
    margin-top: 0em;
    padding: 16px;
  }
}

/* --- */
